body {
  font-family: Lato;
  font-size: 17px;
}

p {
  font-size: 17px;
  color: #111111;
}


.App {
  text-align: left;
  padding: 1em 1em 35px 1em;
  margin: 1em;
  background-color: #f6f6f6;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

div.col {
  padding-left: 0;
}

.form {
  padding: 0 3em 3em;
}

.form section:nth-of-type(1) {
  padding-top:0;
}

.form-check-input {
  margin-top: 0;
}

label {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  align-items: center;
}

.btn button {
  position: relative;
}

button {
  justify-content: flex-end;
}

.spinner-border {
  position: absolute;
  left: calc(50% - 1em);
  top: calc(50% - 1em);
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.theme-color {
  color: #ff5200;
}


.width-1069 {
  width: 1069px;
  padding: 0;
}

.form-content-fixed-width {
  margin: 69px auto 40px;
  text-align: center;
  width: 950px;
  flex-direction: column;
  padding: 0 20px;
}

.width-700 {
  width: 700px;
}

.width-430 {
  width: 430px;
}

.w-90 {
  width: 90% !important;
}

.l-75 {
  left: 75%;
}

.form-content-fixed-width .form-content-image {
  height: 40px;
}

.form-content-fixed-width .form-content-message {
  margin: 15px;
}

#customer-service .form-content-message {
  margin: 0;
}

#enterprise-purchase-form label {
  flex-direction: row;
}

#enterprise-purchase-form input[type=number]::-webkit-outer-spin-button,
#enterprise-purchase-form input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.minus, .plus {
  width: 28px;
  height: 28px;
  border: 1px solid #111111;
}

.quantity {
  width: 70px;
  height: 28px;
  border-top: 1px solid #111111;
  border-bottom: 1px solid #111111;
}

#enterprise-purchase-form .App {
  background-color: #FAFAFA;
}

#enterprise-purchase-form .form-content{
  background-color: #FAFAFA;
}

#enterprise-purchase-form input {
  color: #111110;
}

#enterprise-purchase-form section {
  padding: 45px 0;
}

#enterprise-purchase-form .form-group .col-form-label {
  font-weight: normal;
}

.step-label {
  color: #555555;
}

.step-name {
  font-size: 20px;
}

.business-header {
  flex-direction: column;
  max-width: 1440px;
}

.business-header p {
  text-align: center;
  font-size: 0.8em;
  margin-bottom: 0;
}

.bordered {
  border: 2px solid #333333;
  border-radius: 4px;
}

.column {
  flex-direction: column;
}

.disabled *, #enterprise-purchase-form .disabled input{
  border-color: #B4B4B4;
  color: #b4b4b4;
  pointer-events: none;
}

.business-image-group {
  padding: 2em;
}

.business-header .center{
  flex-direction: column;
}

.business-header img {
  max-width: 190px;
  max-height: 150px;
}

p.business-title {
  line-height: 2.75em;
  font-size: 2.25em;
  font-weight: bold;
  margin-bottom: 0;
}

.business-description {
  max-width: 778px;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  text-align: center;
}

.checkbox {
  text-align: center;
}


.checkbox img {
  width: auto;
  height: 100px;
  margin-bottom: 10px;
}

.checkbox p {
  display: block;
  margin-bottom: 30px;
}

.check {
  display: none;
}

.check+label {
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  user-select: none;
  display: inline-flex;
  justify-content: center;
}

.check+label:before {
  content: '';
  position: absolute;
  top: -15px;
  width: 20px;
  height: 20px;
  background: rgba(128, 128, 128, 0.4);
}

.check:checked+label {
  color: #243441;
}

.check:checked+label:after {
  content: "\2713";
  position: absolute;
  top: -22px;
  color: #000;
  width: 100%;
  text-align: center;
  font-size: 1.3em;
  padding: 1px 0 0 0;
  vertical-align: text-top;
}

.btn {
  text-align: center;
  margin-top: 15px;
  width: 148px;
  height: 44px;
  border: none !important;
}

.btn button, button.btn {
  background: #ff5200;
}

.btn button:hover {
  background: #ff5100ef;
}

/*  Custom Checkbox */
/* The container */
.custom-checkbox-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-content-wrapper {
  position: relative;
  flex-direction: column;
  border: 1px solid #d2d2d2;
  background-color: #fff;
  border-radius: 8px;
  width: 208px;
  height: 250px;
  padding: 0.9em;
}

/* Hide the browser's default checkbox */
.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1px;
  width: 1px;
}

/* Create a custom checkbox */
.custom-checkbox-checkmark {
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  margin-top: 15px;
  background-color: #f6f6f6;
  border: 1px solid #111111;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container:hover input~.custom-checkbox-checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container input:checked~.custom-checkbox-checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox-container input:checked~.custom-checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox-container .custom-checkbox-checkmark:after {
  position: relative;
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#CovidTestKitForm {
  color: #2D3238;
}


#CovidTestKitForm label {
  text-align: left;
  align-items: baseline;
  font-size: 16px;
  font-weight: normal;
  color:#2D3238;
}

#CovidTestKitForm #note {
  min-height: 110px;
}

#CovidTestKitForm h1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 26px;
}

#CovidTestKitForm p {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 64px;
}

/* 
Home page new design
*/

/* New button design */
a.button-large {
  width: 275px !important;
}

.text-no-transform {
  text-transform: none !important;
  font-size: 17px;
  font-weight: normal;
}

/* New Button design end */

/* ticker animation */
@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #4A4A4A;
  padding-left: 100%;
  box-sizing: content-box;
}

.ticker-wrap .ticker {
  display: inline-block;
  height: 51px;
  line-height: 51px;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
}

.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 0 2rem;
  color: white;
}

/* ticker animation end */

/* Donation and other forms  */

.contact-info-content {
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 25px 35px 15px;
  background-color: #fafafa;
  min-height: 200px;
  width: 100%;
}

.contact-info-content h3 {
  font-size: 20px;
  width: 50px;
  height: 24px;
  color: #111111;
  font-weight: bold;
  line-height: 24px;
}

a.contact-info {
  color: #333333 !important;
  text-decoration: underline;
  overflow-wrap: break-word;
}

.business-inquiry-form *,
#inquiry-form-menu *,
li.contact-link-item span {
  font-size: 1em;
  line-height: 21px;
  letter-spacing: 0;
  font-weight: 500;
}

.business-inquiry-form .row.form-group {
  margin-bottom: 12px;
}

.form-group .col-form-label {
  align-items: flex-end;
  text-align: right;
  line-height: 28px;
  padding-right: 0;
  white-space: nowrap;
}

.form-group input,
.form-group textarea,
select {
  border-radius: 4px;
  height: 40px;
  background-color: #fff;
}

select {
  width: 100%;
  border: 1px solid #ced4da;
  color: #495057;
}

.inquiry-form-menu {
  border-bottom: 2px inset rgba(179, 179, 179, 0.5);
}

ul.contact-link-menu {
  list-style: none;
  display: flex;
  align-items: center;
  text-align: center;
  height: 53px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}


ul.contact-link-menu li {
  list-style: none;
  display: inline-block;
  margin-right: 75px;
  height: 100%;
}

li a,
li a span {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #333333;
  height: 100%;
}

a.active span {
  color: #111111 !important;
  font-weight: 700 !important;
  height: 100%;
  border-bottom: 2px solid #ff5200;
}




a#link-to-product {
  text-decoration: underline;
}

.contact-us-header {
  background-color: #f6f6f6;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us-header .contact-message {
  display: inline-block;
  width: 50%;
}

.contact-us-header .contact-message span {
  display: block;
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  color: #111111;
}

.contact-us-header .contact-image {
  max-width: 45%;
  display: inline-block;
}


.donation-item-image {
  max-height: 100px;
  margin-bottom: 10px;
}

.donation-item-title {
  text-align: center;
  color: #111;
  font-size: 1em;
  line-height: normal;
}

.donation-item-subtitle {
  font-size: 14px;
  line-height: 17px;
  color: #666666;
  text-align: center;
  max-width: 166px;
  min-height: 50px;
  margin-bottom: 10px;
}

.donation-item-quantity {
  position: absolute;
  bottom: 30px;
  font-size: 1em;
  white-space: nowrap;
}


.inquiry-form * {
  color: #111111;
}

.inquiry-form .fusion-builder-row.fusion-row,
.inquiry-form-menu .fusion-builder-row.fusion-row {
  max-width: 1068px !important;
}

.inquiry-form-menu {
  box-shadow: 1px 2px 2px 0 rgba(179, 179, 179, 0.5) !important;
  padding-left: 0 !important;

}

.bold {
  font-weight: bold !important;
}

.checkbox-md {
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  margin-top: 10px;
  background-color: #f6f6f6;
  border-radius: 2px;
}


.m-10 {
  margin: 10px;
}

.pointer {
  cursor: pointer;
}


#shipping_address::-webkit-input-placeholder {
  font-size: 15px;
}

#shipping_address::-moz-placeholder {
  font-size: 15px;
}

#shipping_address:-ms-input-placeholder {
  font-size: 15px;
}

#shipping_address::placeholder {
  font-size: 15px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-form {
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw;
  max-width: none !important;
}

.popup-form .App {
  width: 100vw;
  margin: 0 !important;
  max-width: none !important;
}

.form-title {
  text-align: center;
  font-size: 24px;
  line-height: 29px;
  margin: 2em;
  /* font-weight: bold; */
  color: #111111;
}

.form-title-2 {
  text-align: center;
  font-size: 24px;
  line-height: 29px;
  margin: 3rem 0rem;
  font-weight: bold;
  color: #111111;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ced4da;
}

.form-body {
  margin: 0.5rem 0rem;
  font-size: 15px;
  line-height: 29px
}

@media only screen and (min-width: 576px) {
  .form-title-2 {
    margin: 3rem 4rem;
  }
  .form-body {
    margin: 0.5rem 4rem;
  }

  .popup-form .form-title-2, .popup-form .form-body {
    margin: 3rem 0vw !important;
  }

  .popup-form .App {
    padding: 1em 3em 35px 3em !important;
    margin: 0;
  }
}

.form-body .error {
  border: 1px solid red !important;
}

.form-body .error.form-control:focus, .form-body .form-group .custom-dropdown .error.btn:focus {
  box-shadow: 0 0 0 0.05rem transparent !important;
} 

.form-body .form-group[class*="row"] {
  margin-right: 0 !important;
}

.form-body .form-group [class*="col"] {
  padding-right: 0;
  display: inline-block
}

.form-body .form-group label {
  align-items: flex-start !important;
  justify-content: center;
  text-align: left;
  font-weight: 200;
}

.form-body .col.btn, .form-body .col.btn .btn {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 3.5rem;
  width: 148px;
  height: 44px;
  border: none !important;
  padding-right: 0 !important;
}

.form-body .form-group .custom-dropdown .btn {
  margin-top: 0;
  width: 100%;
  text-align: left !important;
  padding: .375rem .75rem !important;
  background-color: #fff;
  color: #ced4da;
  border: 1px solid #ced4da;
  height: 40px;
}

.form-body .form-group .custom-dropdown .btn:focus {
  margin-top: 0;
  width: 100%;
  text-align: left !important;
  padding: .375rem .75rem !important;
  background-color: #fff;
  color: #ced4da;
  box-shadow: 0 0 0 0.05rem #80bdff !important;
  height: 40px;
}

.form-body .form-group .custom-dropdown .dropdown-toggle::after {
  float: right;
  margin: 0.5rem;
  margin-top: 0.7rem;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.form-body .form-group .custom-dropdown .dropdown-menu {
  width: 100%; /* 0.75rem is padding right of dropdown toggle button */
  padding-left: 0;
}

.form-body .form-group .custom-dropdown .dropdown-item {
  padding-left: 0.75rem !important; /* 0.75rem is padding left of dropdown toggle button */
}

/* truncate */
.form-body .form-group .custom-dropdown .btn, .form-body .form-group .custom-dropdown .dropdown-item  {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-body .form-group textarea {
  height: auto !important;
}

.form-body .required {
  margin-left: 1px;
  color: red
}

/* Calendly css */
.calendly-popup {
  padding: 0 !important;
  margin: 0 !important;
  width: 90vw !important;
  max-width: none !important;
  max-height: none !important;
}

#page-region [class*='___container-StyledContainer__'] {
  max-width: none !important;
}
/* End of Calendly css */

.icon {
  position: relative;
  width: 20px;
  height: 20px;
}

.icon-info {
  bottom: 2px;
}

.disclaimer {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.file-reference {
  color: #666666;
  font-size: 14px;
  height: 17px;
  text-decoration: underline;
}

.required {
  text-align: right;
  margin-bottom: 23px;
}

.align-left {
  text-align: left;
  align-self: baseline;
}


.header-space-around {
  width: 1068px;
  margin: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between
}

label.col-form-checkbox.col-sm-3.col-form-label {
  text-align: right;
  align-items: flex-end;
}

.page-reference {
  color: #ff5200;
  text-decoration: underline;
}

#customer-service {
  width: 1068px;
}


.horizontal-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.no-margin {
  margin: 0;
}


.checkbox-content-wrapper {
  display: flex;
  align-items: center;
}

/* Mobile design and fixes */

@media only screen and (max-width: 1200px) {

  .header-space-around,
  #customer-service,
  .width-700,
  .width-430,
  .form-content-fixed-width,
  .customer-service-form {
    width: 100%;
  }

  .business-header .business-image-group {
    padding: 1em;
  }

  .form-content-fixed-width {
    padding: 16px;
  }

  .contact-info-wrapper .contact-info-content {
    display: inline-block;
    max-width: none;
  }

  #customer-service {
    padding: 2em;
  }

  .customer-service-form {
    /* margin: 30px; */
    overflow-x: hidden;
    min-width: calc(100% - 40px);
  }

  .customer-service-form-content {
    margin: 0 20px;
  }

  .header-space-around {
    margin: 30px 20px;
  }

  .contact-link-menu {
    overflow: auto;
  }

  .contact-us-header .contact-message span {
    font-size: 1.5em;
    line-height: 30px;
  }

  .inquiry-form-menu {
    padding: 0 0 0 16px !important;
  }

  .inquiry-form-menu li:last-of-type {
    padding-right: 16px;
  }

  .form-content-fixed-width .form-content-message {
    text-align: left;
    font-size: 18px;
  }

  form.form {
    padding: 0;
  }

  .form-title {
    font-size: 20px;
    margin: 0;
  }

  .required {
    margin-bottom: 10px;
  }

  .donation-item-subtitle {
    min-height: 50px;
  }


  .donation-item-title {
    text-align: center;
    color: #333333;
    min-height: normal;
  }

}


@media only screen and (max-width: 576px) {

  .form-group .col-form-label,
  label.col-form-checkbox.col-sm-3.col-form-label {
    text-align: left !important;
    align-items: flex-start;
  }

  #enterprise-purchase-form {
    padding: 1rem;
  }

  .App.container {
    padding-bottom: 35px;
  }
  
  .business-header p,  .business-header span{
    font-size: 0.9rem;
  }
  
  .business-header .business-image-group {
    padding: 0em;
  }
  
  .business-header img {
    max-width: 190px;
    max-height: 120px;
  }
  
  p.business-title {
    text-align: center;
    font-size: 1.5em;
    line-height: 1.2em;
    margin-bottom: 20px;
  }

  .col-form-checkbox {
    left: 10px;
  }

  ul.contact-link-menu li {
    margin-right: 1em;
  }

  li.contact-link-item span {
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.2px;
  }


  .col-form-disclaimer {
    margin: 20px 0 0 10px;
    padding: 20px;
    top: -24px;
    width: calc(100% - 25px);
  }

  .donation-item {
    margin: 0 2%;
  }

  .donation-item-subtitle {
    min-height: 70px;
  }

  .donation-item-image {
    height: 67px !important;
  }

  .custom-checkbox-container {
    position: relative;
    left: 0;
    padding-left: 0;
    margin: 0;
  }

  .checkbox-content-wrapper {
    width: 96%;
  }

  .col-6 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .custom-checkbox-checkmark {
    margin: 15px 0 0 15px;
  }

  .form-title {
    font-size: 17px;
  }

  .service-message {
    font-size: 15px;
  }
}